import React from 'react';

interface IIconProps {
  width?: number;
  className?: string;
}

const ShareIcon = (props: IIconProps) => {
  const { width = 24, className } = props;

  return (
    <svg
      className={className}
      width={width}
      height={width}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h24v24H0z" />
        <path
          d="M12.316 10.422a.893.893 0 0 1 1.262 0c1.731 1.731 1.85 4.462.21 6.102l-2.314 2.314c-1.64 1.64-4.37 1.521-6.102-.21-1.731-1.732-1.85-4.462-.21-6.102l1.736-1.736a.893.893 0 1 1 1.262 1.263l-1.736 1.735c-.916.917-.847 2.52.21 3.577 1.058 1.058 2.66 1.127 3.578.21l2.314-2.314c.917-.917.847-2.52-.21-3.577a.893.893 0 0 1 0-1.262zm.21-5.26c1.64-1.64 4.37-1.521 6.102.21 1.731 1.732 1.85 4.462.21 6.102l-1.736 1.736a.893.893 0 1 1-1.262-1.263l1.736-1.735c.916-.917.847-2.52-.21-3.577-1.058-1.058-2.66-1.127-3.578-.21l-2.314 2.314c-.917.917-.847 2.52.21 3.577a.893.893 0 1 1-1.262 1.262c-1.731-1.731-1.85-4.462-.21-6.102z"
          fill="#111"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

export default React.memo(ShareIcon);
