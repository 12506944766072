import React from 'react';

import { Avatar } from '../components/Avatar/Avatar';
import { PostStats } from '../components/PostStats';

import style from './PostAuthor.scss';

import cn from 'classnames';

interface IProps {
  readTime: string;
  publishedAt: string;
  primary_author: {
    name: string;
    slug: string;
    profile_image: string;
  };
  location: string;
}

export const PostAuthor = ({ publishedAt, primary_author, readTime, location }: IProps) => {
  return (
    <div className={cn(style.author)}>
      <Avatar
        className={style.profileImage}
        profile_image={primary_author.profile_image}
        name={primary_author.name}
      />
      <div>
        <a href={`${location}/author/${primary_author.slug}`}>{primary_author.name}</a>
        <PostStats publishedAt={publishedAt} readTime={readTime} />
      </div>
    </div>
  );
};
