import React, { Component } from 'react';

import { Bar } from './Bar';

import postStyle from '../../styles/pages/posts.scss';

export class ProgressBar extends Component {
  state = {
    scrollActiveClass: false,
    scrollPostion: 0,
  };

  articleNode: any;

  listenToScrollEvent = () => {
    document.addEventListener('scroll', () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  };

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100);

    if (scrollTop > 120) {
      this.setState({
        scrollActiveClass: true,
      });
    } else {
      this.setState({
        scrollActiveClass: false,
      });
    }

    this.setState({
      scrollPostion,
    });
  };

  getDocHeight = () => {
    if (this.articleNode) {
      return this.articleNode.getBoundingClientRect().height;
    }

    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  componentDidMount() {
    this.articleNode = document.getElementsByClassName(postStyle.singleArticle)[0];
    this.listenToScrollEvent();
  }

  render() {
    return <Bar isFixed={this.state.scrollActiveClass} scroll={this.state.scrollPostion} />;
  }
}
