import React, { CSSProperties } from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { PostAuthor } from '../components/PostAuthor';
import { PostSocialLinks } from '../components/PostSocialLinks';
import { ProgressBar } from '../components/ProgressBar/ProgressBar';
import { ProgressBarDesktop } from '../components/ProgressBar/ProgressBarDesktop';

import stylePost from '../styles/pages/posts.scss';

import { readingTime } from '@tryghost/helpers';
import { Tags } from '@tryghost/helpers-gatsby';
import cn from 'classnames';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Sticky, StickyContainer } from 'react-sticky';

interface IPostProps {
  data: {
    ghostPost: {
      codeinjection_styles?: any;
      title: string;
      excerpt: string;
      custom_excerpt: string | null;
      subTitle: string;
      html: string;
      feature_image?: string;
      tags: Array<{
        slug: string;
        name: string;
      }>;
      published_at: string;
      primary_author: {
        slug: string;
        name: string;
        profile_image: string;
      };
    };
  };
  location: any;
}

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }: IPostProps) => {
  const post = data.ghostPost;

  return (
    <>
      <MetaData data={data} location={location} />
      <Helmet>
        <style type="text/css">{`${post.codeinjection_styles}`}</style>
        {process.env.COMMENTO_SCRIPT_SRC ? (
          <script
            defer
            src={process.env.COMMENTO_SCRIPT_SRC}
            data-css-override="/commento.css"
            data-no-fonts="true"
          />
        ) : null}
      </Helmet>
      <Layout postTitle={post.title} headerClassName={stylePost.header}>
        <StickyContainer className={stylePost.sticky}>
          <Sticky>
            {({ style, isSticky }: { style: CSSProperties; isSticky: boolean }) => (
              <div style={{ ...style }}>
                <ProgressBarDesktop
                  className={isSticky ? stylePost.stickyPrograssBar : ''}
                  title={post.title}
                  content={post.html}
                />
              </div>
            )}
          </Sticky>
          <div className="container">
            <ProgressBar />
            <article className={stylePost.singleArticle}>
              <section className={stylePost.singleArticleFullContent}>
                {post.tags && (
                  <div className="post-card-tags">
                    <Tags
                      classes={stylePost.singleArticleTags}
                      post={post}
                      visibility="public"
                      limit={1}
                      permalink="/tag/:slug/"
                    />
                  </div>
                )}
                <h1 className={stylePost.singleArticleTitle}>{post.title}</h1>
                {post.custom_excerpt ? (
                  <p className={stylePost.singleArticleExcerpt}>{post.custom_excerpt}</p>
                ) : null}
                <PostAuthor
                  location={location.origin}
                  publishedAt={post.published_at}
                  primary_author={post.primary_author}
                  readTime={readingTime(post)}
                />
              </section>
              {post.feature_image ? (
                <figure className={stylePost.singleArticleFeatureImage}>
                  <img src={post.feature_image} alt={post.title} />
                </figure>
              ) : null}

              {/* The main post content */}
              <section
                className={cn(
                  stylePost.singleArticleFullContent,
                  stylePost.singleArticleBodyContent
                )}
                dangerouslySetInnerHTML={{ __html: post.html }}
              />
              <section className={stylePost.singleArticleFullContent}>
                <PostSocialLinks feature_image={post.feature_image} />
                {post.tags && (
                  <div className={stylePost.singleArticleFooterTags}>
                    <Tags
                      classes={stylePost.singleArticleTags}
                      post={post}
                      visibility="public"
                      separator=""
                      permalink="/tag/:slug/"
                    />
                  </div>
                )}
              </section>
            </article>
          </div>
          {/* <section className={stylePost.commentsContainer}>
            <h2 className={stylePost.commentsHeading}>Comments</h2>
            <div id="commento" />
          </section> */}
        </StickyContainer>
        <BigBanner />
      </Layout>
    </>
  );
};

export default Post;

export const postQuery = graphql`
  query($slug: String!) {
    ghostPost(slug: { eq: $slug }) {
      ...GhostPostFields
    }
  }
`;
