import React from 'react';

import style from './ProgressBar.scss';

import cn from 'classnames';

export interface IProgressBarProps {
  scroll: number;
  isFixed?: boolean;
}

export function Bar({ scroll, isFixed }: IProgressBarProps) {
  return (
    <div className={cn(isFixed ? style.fixed : null, style.progressBar)}>
      <div className={style.filler} style={{ width: `${scroll}%` }} />
    </div>
  );
}
