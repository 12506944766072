import React from 'react';

import style from './ProgressBarDesktop.scss';

import cn from 'classnames';

interface IProps {
  content: string;
  title: string;
  titleId?: string;
  className?: string;
}

interface IState {
  sideNav: [];
  currentActiveHeading: string;
  isHovered: boolean;
}

const defaultTitleId: string = 'main-heading';

export class ProgressBarDesktop extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      currentActiveHeading: '',
      isHovered: true,
      sideNav: [],
    };
  }

  componentDidMount() {
    const { titleId = defaultTitleId } = this.props;
    /*==================================================
   * INTERSECTION OBSERVER FOR UPDATE SIDEBAR ON SCROLL
   ==================================================*/
    let observer: IntersectionObserver;
    let currentActiveHeading: any = '';
    if ('IntersectionObserver' in window) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio > 0) {
              currentActiveHeading = entry.target.id || titleId;
            }
          });

          // don't animate sidebar on scroll if hovered
          const sideNavWrapper = document.getElementById('sideNavWrapper');
          if (
            sideNavWrapper &&
            sideNavWrapper.parentElement &&
            sideNavWrapper.parentElement.querySelector(':hover') !== sideNavWrapper
          ) {
            // get first visible heading on page and make active in sidebar
            this.updateSideNav(currentActiveHeading);
          }
        },
        {
          rootMargin: '0px',
          threshold: 0.5,
        }
      );

      // observe all headings in the "content section"
      const contentHeadings = document.querySelectorAll('h1, h2, h3');
      contentHeadings.forEach((heading) => {
        observer.observe(heading);
      });
    }

    setTimeout(() => {
      this.setState({ isHovered: !this.state.isHovered });
    }, 1000);
  }

  updateSideNav(currentActiveHeading: string) {
    const { content, title, titleId = defaultTitleId } = this.props;
    const sideNav: any = [];
    const doc = new DOMParser().parseFromString(content, 'text/html');
    const headings = doc.querySelectorAll('h1, h2, h3');

    const addSideNavItem = (id: string, tagName: 'H1' | 'H2' | 'H3', text: string) => {
      sideNav.push(
        <li
          key={id}
          className={cn(style[tagName], {
            [style.active]: currentActiveHeading === id,
          })}
          data-id={id}
          onClick={this.handleSideNavLiClick}
        >
          <span className={style.marker} />
          <span className={style.text}>{text}</span>
        </li>
      );
    };

    addSideNavItem(titleId, 'H1', title);

    headings.forEach((val: any) => {
      const headingRendered = document.createElement('div');
      headingRendered.innerHTML = val.innerHTML;

      addSideNavItem(
        val.id,
        val.tagName,
        headingRendered.textContent ? headingRendered.textContent.trim() : ''
      );
    });

    this.setState({ sideNav });
  }

  handleSideNavLiClick = (e: any): void => {
    const { titleId = defaultTitleId } = this.props;
    const id = e.target.dataset.id;
    const el = document.getElementById(id);

    this.updateSideNav(id);

    if (id === titleId) {
      // if main page title, scroll to top of page
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    } else if (el) {
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = el.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top - 96;

      window.scrollTo({
        behavior: 'smooth',
        top: offset,
      });
    }
  };

  render() {
    return (
      <div id="sideNavWrapper" className={cn(this.props.className, style.desktopProgressNav)}>
        <ul className={this.state.isHovered ? style.isHovered : ''}>{this.state.sideNav}</ul>
      </div>
    );
  }
}
